<template>
  <b-modal :title="pageTitle" v-model="showModal" size="lg" @hide="$emit('hide')" scrollable>
    <b-row>
      <b-col>
        <b-form @submit.stop.prevent="saveItem">
          <table class="edit-table">
            <tr>
              <td><label for="memberorg" class="required">MEMBER:</label></td>
              <td>
                <b-form-select
                    id="memberorg"
                    name="memberorg"
                    v-model="$v.form.memberorg.$model"
                    :options="memberOptions"
                    :state="validateState('memberorg')"
                    aria-describedby="memberorg-live-feedback"/>
                <b-form-invalid-feedback
                    id="memberorg-live-feedback"
                >This is a required field</b-form-invalid-feedback>
              </td>
              <td></td>
              <td></td>
            </tr><tr>
            <td><label for="name" class="required">NAME:</label></td>
            <td>
              <b-form-input
                  id="name"
                  name="name"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="name-live-feedback"/>
              <b-form-invalid-feedback
                  id="name-live-feedback"
              >This is a required field and cannot be more than {{$v.form.name.$params.maxLength.max}} letters</b-form-invalid-feedback>
            </td>
            <td><label for="premise_id">PREMISE ID (PID):</label></td>
            <td>
              <b-form-input
                  id="premise_id"
                  name="premise_id"
                  v-model="$v.form.premise_id.$model"
                  :state="validateState('premise_id')"
                  aria-describedby="code-live-feedback"
                  maxlength="20"
                  trim
              />
              <b-form-invalid-feedback
                  id="code-live-feedback"
              >This field cannot be more than {{$v.form.premise_id.$params.maxLength.max}} number</b-form-invalid-feedback>
            </td>
          </tr>
            <tr>
              <td><label for="landlocation">LAND DESCRIPTION:</label></td>
              <td>
                <b-form-input
                    id="landlocation"
                    name="landlocation"
                    v-model="$v.form.landlocation.$model"
                    :state="validateState('landlocation')"
                    aria-describedby="landlocation-live-feedback"/>
                <b-form-invalid-feedback
                    id="landlocation-live-feedback"
                >This field cannot be more than {{$v.form.landlocation.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
              <td><label for="postal">POSTAL CODE:</label></td>
              <td>
                <b-form-input
                    id="postal"
                    name="postal"
                    class="text-uppercase"
                    v-mask="postalMask"
                    v-model="$v.form.postal.$model"
                    :state="validateState('postal')"
                    aria-describedby="postal-live-feedback"/>
                <b-form-invalid-feedback
                    id="postal-live-feedback"
                >This field cannot be more than {{$v.form.postal.$params.maxLength.max}} characters and must be a valid postal code.</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="city">CITY:</label></td>
              <td>
                <b-form-input
                    id="city"
                    name="city"
                    v-model="$v.form.city.$model"
                    :state="validateState('city')"
                    aria-describedby="city-live-feedback"/>
                <b-form-invalid-feedback
                    id="city-live-feedback"
                >This field cannot be more than {{$v.form.city.$params.maxLength.max}} letters</b-form-invalid-feedback>
              </td>
              <td><label for="province" class="required">PROVINCE:</label></td>
              <td>
                <b-form-select
                        id="province"
                        name="province"
                        v-model="$v.form.province.$model"
                        :options="provinceOptions"
                        :state="validateState('province')"
                        aria-describedby="province-live-feedback">
                  </b-form-select>
                <b-form-invalid-feedback id="province-live-feedback">Please select a province</b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td><label for="lat">LATITUDE:</label></td>
              <td>
                <!-- <b-input-group append="DD"> -->
                <b-form-input
                    id="lat"
                    name="lat"
                    v-model="$v.form.lat.$model"
                    :state="validateState('lat')"
                    aria-describedby="lat-live-feedback"/>
                <!-- </b-input-group> -->
                <b-form-invalid-feedback
                    id="lat-live-feedback"
                >This field must be decimal number between {{$v.form.lat.$params.between.min}} and {{$v.form.lat.$params.between.max}} degrees</b-form-invalid-feedback>
              </td>
              <td><label for="lon">LONGITUDE:</label></td>
              <td>
                <!-- <b-input-group append="DD"> -->
                <b-form-input
                    id="lon"
                    name="lon"
                    v-model="$v.form.lon.$model"
                    :state="validateState('lon')"
                    aria-describedby="lon-live-feedback"/>
                <!-- </b-input-group> -->
                <b-form-invalid-feedback
                    id="lon-live-feedback"
                >This field must be decimal number between {{$v.form.lon.$params.between.min}} and {{$v.form.lon.$params.between.max}} degrees</b-form-invalid-feedback>
              </td>
            </tr>
          </table>
          <input type="submit" class="d-none"/>
        </b-form>
      </b-col>
    </b-row>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0 && $store.getters.isSupervisor" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  // import { roles } from '@/variables'
  import {required, decimal, between, maxLength} from 'vuelidate/lib/validators'
  import { postalMask, provinceCodes } from '@/variables'

  // const post_code = helpers.regex('post_code', /^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJKLMNPRSTVWXYZ][ -]?\\d[ABCEGHJKLMNPRSTVWXYZ]\\d$/)
  // TODO: input validation
  export default {
    name: 'EditLocation',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      locationId: Number,
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        form: {
          // location attributes
          name: null,
          premise_id: null,
          landlocation: null,
          city: null,
          province: null,
          postal: null,
          lat: null,
          lon: null,
          memberorg: null
        },
        ownerOptions: [],
        memberOptions: [],
        postalMask
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations: {
      form: {
        name: {
          required,
          maxLength: maxLength(30)
        },
        premise_id: {
          // required,
          maxLength: maxLength(20)
        },
        landlocation: {
          maxLength: maxLength(20)
        },
        city: {
          maxLength: maxLength(35)
        },
        province: {
          required
        },
        postal: {
          maxLength: maxLength(7),  // US postal code is max 9 characters
          function (value) {
            if (value == null || value == '') return true
            return this.postalFilter(value) != null
          }
          // post_code
        },
        lat: {
          decimal,
          between: between(-90, 90)
        },
        lon: {
          decimal,
          between: between(-180, 180)
        },
        memberorg: {
          required
        }
      }
    },
    mounted () {
      this.fetchMembers()
    },
    watch: {
      show: function (newVal) {
        this.form = {
          name: null,
          premise_id: null,
          landlocation: null,
          city: null,
          province: null,
          postal: null,
          lat: null,
          lon: null,
          memberorg: null
        }
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      }
    },
    computed: {
      isNew: function () {
        return this.locationId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Location' : 'Update Location'
      },
      provinceOptions () {
        let opts = Object.entries(provinceCodes).map(([k, v]) => ({ value: k, text: v }))
        opts.unshift({ value: null, text: 'Please select a province' })
        return opts
      }
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches the data for the specific location to be edited.
       */
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/locations/${this.locationId}/${this.archived}`)
            .then(response => {
              console.log('EditLocation fetchData', response)
              let loc = response.data
              this.form = {
                name: loc.name,
                premise_id: loc.premise_id,
                lat: loc.lat,
                lon: loc.lon,
                landlocation: loc.landlocation,
                city: loc.city,
                province: loc.province,
                postal: loc.postal,
                memberorg: loc.memberorg.id
              }
            })
            .catch(e => {
              console.log(e, e.response)
            })
        }
      },
      /**
       * This fetches the data for all selectable memberorgs.
       */
      fetchMembers () {
        this.$http.get(`/memberorgs/?get_all=true`)
          .then(response => {
            console.log('EditLocation fetchMembers', response)
            this.memberOptions = response.data.map(e => ({ value: e.id, text: `${e.business_name} (${e.member_id})` }))
          })
          .catch(e => {
            console.log('EditLocation fetchMembers', e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This checks the form using vuelidate and then edits the location if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        // in case of autofill entering different format which bypasses inputmask raw value
        this.form.postal = this.postalFilter(this.form.postal)
        let request = null
        if (this.isNew) {
          // Post to create
          request = this.$http.post('/locations/', this.form)
        } else {
          // Patch to update
          request = this.$http.patch(`/locations/${this.locationId}/${this.archived}`, this.form)
        }
        request
                .then(response => {
                  console.log(response)
                  this.$emit(this.isNew ? 'create' : 'save', response.data)
                  this.showModal = false
                })
                .catch(e => {
                  console.log(e, e.response)
                  this.setAlert({ variant: 'danger', message: e.message })
                })
      },
      /**
       * This will delete the location
       */
      deleteItem () {
        this.$http.delete(`/locations/${this.locationId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete')
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will restore a deleted location
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/locations/${this.locationId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditLocation restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditLocation restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  td {
    padding: 5px;
  }
</style>
