<template>
  <b-modal :title="pageTitle" v-model="showModal" size="lg" @hide="$emit('hide')" scrollable>
    <b-form>
      <b-row>
        <b-col>
          <b-form @submit.prevent.stop="saveItem">
            <table class="edit-table">
              <tr>
                <td>
                    <label for="location" class="required">LOCATION:</label>
                </td>
                <td>
                    <b-form-select
                            id="location"
                            name="location"
                            v-model="$v.form.location.$model"
                            :options="locationOptions"
                            :state="validateState('location')"
                            aria-describedby="location-live-feedback"/>
                    <b-form-invalid-feedback
                            id="location-live-feedback"
                    >This is a required field</b-form-invalid-feedback>
                </td>
                <td>
                  <label for="gate_type" class="required">GATE TYPE:</label>
                </td>
                <td>
                  <b-form-select
                          id="gate_type"
                          name="gate_type"
                          v-model="$v.form.gate_type.$model"
                          :options="gateTypeOptions"
                          :state="validateState('gate_type')"
                          aria-describedby="gate_type-live-feedback"/>
                  <b-form-invalid-feedback
                          id="gate_type-live-feedback"
                  >This is a required field</b-form-invalid-feedback>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="gate_id" class="required">GATE ID:</label>
                </td>
                <td>
                  <b-form-input
                          id="gate_id"
                          name="gate_id"
                          v-model="$v.form.gate_id.$model"
                          :state="validateState('gate_id')"
                          aria-describedby="gate_id-live-feedback"/>
                  <b-form-invalid-feedback
                          id="gate_id-live-feedback"
                  >This is a required field and cannot be more than {{$v.form.gate_id.$params.maxLength.max}} letters</b-form-invalid-feedback>
                </td>
                <td>
                  <label for="gate_name">GATE NAME:</label>
                </td>
                <td>
                  <b-form-input
                          id="gate_name"
                          name="gate_name"
                          v-model="$v.form.gate_name.$model"
                          :state="validateState('gate_name')"
                          aria-describedby="gate_name-live-feedback"/>
                  <b-form-invalid-feedback
                          id="gate_name-live-feedback"
                  >This field cannot be more than {{$v.form.gate_name.$params.maxLength.max}} letters</b-form-invalid-feedback>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="gate_id">GATE NUMBER:</label>
                </td>
                <td>
                  <b-form-input
                      id="gate_number"
                      name="gate_number"
                      type="number"
                      v-model="$v.form.gate_number.$model"
                      :state="validateState('gate_number')"
                      aria-describedby="gate_number-live-feedback"/>
                  <b-form-invalid-feedback
                      id="gate_number-live-feedback"
                  >This field needs to be numeric</b-form-invalid-feedback>
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>
            <input type="submit" class="d-none"/>
          </b-form>
        </b-col>
      </b-row>
    </b-form>
    <template v-slot:modal-footer>
      <div>
        <b-button class="mr-1" variant="danger" v-if="!isNew && archived.length == 0" @click="deleteItem">DELETE</b-button>
        <b-button class="mr-1" variant="success" v-if="!isNew && archived.length > 0" @click="restoreItem">RESTORE</b-button>
        <b-button class="mr-1" variant="secondary" @click="showModal = false">CANCEL</b-button>
        <b-button variant="primary" @click="saveItem">{{ isNew ? 'SAVE' : 'UPDATE' }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { gateTypes } from '@/variables'
  import {required, maxLength, numeric} from 'vuelidate/lib/validators'
  export default {
    name: 'EditGate',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      gateId: Number,
      locations: {
        type: Array,
        required: true
      },
      archived: {
        type: String,
        default: () => ''
      }
    },
    data () {
      return {
        showModal: false,
        form: {
          // gate attributes
          location: null,
          gate_type: null,
          gate_id: null,
          gate_name: null,
          gate_number: null,
        },
        // locationOptions: [],
      }
    },
    /**
     * This is used for validating the form fields
     */
    validations:{
      form:{
        location: {
          required
        },
        gate_type: {
          required
        },
        gate_id: {
          required,
          maxLength: maxLength(20)
        },
        gate_name: {
          maxLength: maxLength(75)
        },
        gate_number: {
          numeric
        }
      }
    },
    watch: {
      show: function (newVal) {
        this.form = {
          location: null,
          gate_type: null,
          gate_id: null,
          gate_name: null,
          gate_number: null
        }
        this.$v.$reset()
        if (newVal) this.fetchData()
        this.showModal = newVal
      }
    },
    computed: {
      isNew: function () {
        return this.gateId == null
      },
      pageTitle: function () {
        return this.isNew ? 'New Gate' : 'Update Gate'
      },
      gateTypeOptions: () => Object.entries(gateTypes).map(([k, v]) => ({ value: k, text: v })),
      locationOptions: function () {
        return this.locations.map(location => ({ value: location.id, text: location.name }))
      },
    },
    methods: {
      validateState (name) {
        const { $dirty, $error } = this.$v.form[name]
        return $dirty ? $error ? false : null : null
      },
      /**
       * This fetches data for the specific gate to be edited.
       */
      fetchData () {
        if (!this.isNew) {
          this.$http.get(`/gates/${this.gateId}/${this.archived}`)
            .then(response => {
              console.log('EditGate fetchData', response)
              let gate = response.data
              this.form.location = gate.location.id
              this.form.gate_type = gate.gate_type
              this.form.gate_id = gate.gate_id
              this.form.gate_name = gate.gate_name
              this.form.gate_number = gate.gate_number
            })
            .catch(e => {
              console.log(e, e.response)
              this.setAlert({ variant: 'danger', message: e.message })
            })
        }
      },
      /**
       * This checks the form using vuelidate and then edits the gate if the provided information is acceptable.
       */
      saveItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        let request = null
        // console.log(this.form)
        if (this.isNew) {
          // Post to create
          request = this.$http.post('/gates/', this.form)
        } else {
          // Put to update
          request = this.$http.patch(`/gates/${this.gateId}/${this.archived}`, this.form)
        }
        request
          .then(response => {
            console.log(response)
            this.$emit(this.isNew ? 'create' : 'save', response.data)
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will delete the gate
       */
      deleteItem () {
        this.$http.delete(`/gates/${this.gateId}/`)
          .then(response => {
            console.log(response)
            this.$emit('delete')
            this.showModal = false
          })
          .catch(e => {
            console.log(e, e.response)
            this.setAlert({ variant: 'danger', message: e.message })
          })
      },
      /**
       * This will restore a deleted gate
       */
      restoreItem () {
        this.$v.form.$touch()
        if (this.$v.form.$anyError) {
          return
        }
        this.form = this.nullBlanks(this.form)
        this.form.archived = false
        this.$http.patch(`/gates/${this.gateId}/${this.archived}`, this.form)
          .then(response => {
            console.log('EditGate restoreItem', response)
            this.$emit('restore')
            this.showModal = false
          })
          .catch(e => {
            console.log('EditGate restoreItem', e, e.response)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
td {
  padding: 5px;
}
</style>
