import { render, staticRenderFns } from "./EditGate.vue?vue&type=template&id=47e34be2&scoped=true&"
import script from "./EditGate.vue?vue&type=script&lang=js&"
export * from "./EditGate.vue?vue&type=script&lang=js&"
import style0 from "./EditGate.vue?vue&type=style&index=0&id=47e34be2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e34be2",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormSelect, BFormInvalidFeedback, BFormInput, BForm, BCol, BRow, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormSelect, BFormInvalidFeedback, BFormInput, BForm, BCol, BRow, BButton, BModal})
    

export default component.exports