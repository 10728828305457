import { render, staticRenderFns } from "./EditLocation.vue?vue&type=template&id=ab7e15ea&scoped=true&"
import script from "./EditLocation.vue?vue&type=script&lang=js&"
export * from "./EditLocation.vue?vue&type=script&lang=js&"
import style0 from "./EditLocation.vue?vue&type=style&index=0&id=ab7e15ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab7e15ea",
  null
  
)


    import installComponents from "!/home/ubuntu/URCHIN-Server/client/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormSelect, BFormInvalidFeedback, BFormInput, BForm, BCol, BRow, BButton, BModal} from 'bootstrap-vue'
    installComponents(component, {BFormSelect, BFormInvalidFeedback, BFormInput, BForm, BCol, BRow, BButton, BModal})
    

export default component.exports