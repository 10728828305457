/**
 * Filtering functions for card filtering pages.
 * Importing components need to put objects into the `filterArray` variable and
 * bind `searchText` to an input, and use `itemsFiltered` as the resulting
 * array for display.
 * @type {Object}
 */
export const cardFilterMixin = {
  data: function () {
    return {
      searchText: '',
      filterArray: []
    }
  },
  computed: {
    filterFn: function () {
      // bascially taken from Bootstrap table filtering setup
      let pattern = this.searchText.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').replace(/[\s\uFEFF\xA0]+/g, '\\s+')
      let regex = new RegExp(`.*${pattern}.*`, 'i')
      const fn = (item, index) => {
        regex.lastIndex = 0
        return regex.test(this.filterArray[index])
      }
      return fn
    },
    itemsFiltered: function () {
      return this.items.length > 0 ? this.items.filter(this.filterFn) : this.items
    }
  },
  methods: {
    stringifyObjectValues: function (obj) {
      if (typeof obj == 'string')
        return obj.toString()
      return Object.values(obj).sort().filter(v => v != null).map(k => this.stringifyObjectValues(k)).join(' ')
    }
  }
}

/**
 * Mixin for toggling sidepanels open/closed. Currently only used on viewport
 * 'sm' or smaller. Importing components need to set up a button to call the
 * `toggleSidepanel` function and bind `sidepanelClass` to the
 * sidepanel div, and optionally bind `sidepanelBtnClass` to the toggle button.
 * @type {Object}
 */
export const sidepanelMixin = {
  data: function () {
    return {
      isOpen: false
    }
  },
  watch: {
    '$route': function () {
      if (this.isOpen) {
        this.toggleSidepanel()
      }
    }
  },
  computed: {
    sidepanelClass: function () {
      return ['sidepanel', 'd-flex', 'p-3', 'flex-column', this.isOpen ? 'open' : '']
    },
    sidepanelBtnClass: function () {
      return ['sidepanel-toggle-button', this.isOpen ? 'open' : '']
    }
  },
  methods: {
    toggleSidepanel: function () {
      this.isOpen = !this.isOpen
    }
  }
}
